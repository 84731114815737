<template>
    <div class="main-content section" style="background-color: rgb(29, 36, 40); padding-top: 0">

        <!-- Header -->
        <div class="header bg-gradient py-7 py-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5 mb-5">
                          <router-link :to="nlWebsite()">
                            <img src="/assets/img/logo-NL-avec-SJ-blanc.png" alt=""/>
                          </router-link>
                        </div>
                        <div class="align-content-end">
                            <LangSelector @onLangChange="onLangChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12 p-0">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-header bg-transparent">
                            <div class="text-muted text-center mt-2 w-100">
                                <h1>{{ $t('register.title') }}</h1>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <form id="registration" ref="registerForm" class="mainform" novalidate>
                                <div class="row">
                                    <div class="col-md-12 col-lg-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="firstname"
                                                    >{{ $t('register.firstname') }} <span class="required"
                                                /></label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-user-tie"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="firstname"
                                                            v-model="user.firstname"
                                                            class="form-control"
                                                            required
                                                            :placeholder="$t('register.firstname')"
                                                            type="text"
                                                        />
                                                        <div class="invalid-feedback">
                                                            {{ $t('register.error.mandatory') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label for="lastname"
                                                    >{{ $t('register.lastname') }} <span class="required"
                                                /></label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-user-tie"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="lastname"
                                                            v-model="user.lastname"
                                                            class="form-control"
                                                            required
                                                            :placeholder="$t('register.lastname')"
                                                            type="text"
                                                        />
                                                        <div class="invalid-feedback">
                                                            {{ $t('register.error.mandatory') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-4 offset-lg-2">
                                        <label for="phone">{{ $t('register.phone') }} <span class="required" /></label>
                                        <vue-tel-input
                                            v-model="user.telephone"
                                            :default-country="country[$i18n.locale]"
                                            :input-options="options"
                                            @validate="setValid"
                                        />
                                        <div v-show="validated && (!phone || !phone.valid)" class="errorPhone">
                                            <span v-if="user.telephone.length <= 0">{{
                                                $t('register.error.mandatory')
                                            }}</span>
                                            <span v-else>{{ $t('register.error.phoneFormat') }}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <label for="email">{{ $t('register.email') }} <span class="required" /></label>
                                        <div class="form-group mb-3 pt-lg-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="fas fa-at" /></span>
                                                </div>
                                                <input
                                                    id="email"
                                                    v-model="user.email"
                                                    class="form-control"
                                                    required
                                                    placeholder="Email"
                                                    type="email"
                                                />
                                                <div class="invalid-feedback">
                                                    {{ $t('register.error.mandatory') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-6">
                                        <label for="password"
                                            >{{ $t('register.password') }} <span class="required"
                                        /></label>
                                        <div class="form-group mb-1 pt-lg-0">
                                            <div class="input-group has-validation">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="fas fa-lock" /></span>
                                                </div>
                                                <input
                                                    id="password"
                                                    ref="password"
                                                    v-model="user.password"
                                                    class="form-control"
                                                    :class="{
                                                        error: validationErrors.password.length > 0
                                                    }"
                                                    :type="passwordType"
                                                    :placeholder="$t('register.password')"
                                                    required
                                                    @blur="onBlurPasswordValidation"
                                                />
                                                <div class="input-group-append">
                                                    <span class="input-group-text" @click="onPasswordVisible"
                                                        ><i class="fas fa-eye"
                                                    /></span>
                                                </div>
                                                <div class="invalid-feedback">
                                                    {{ $t('register.error.mandatory') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="password__validation__errors">
                                            <p v-for="(error, index) in validationErrors.password" :key="index">
                                                {{ error.value }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <label for="password-confirm"
                                            >{{ $t('register.passwordConfirmation') }} <span class="required"
                                        /></label>
                                        <div class="form-group mb-1 pt-lg-0">
                                            <div class="input-group has-validation">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="fas fa-lock" /></span>
                                                </div>
                                                <input
                                                    id="password-confirm"
                                                    ref="password_confirmation"
                                                    v-model="user.password_confirmation"
                                                    class="form-control"
                                                    :type="passwordConfirmType"
                                                    :placeholder="$t('register.passwordConfirmation')"
                                                    required
                                                    @blur="onBlurPasswordConfirmValidation"
                                                />
                                                <div class="input-group-append">
                                                    <span class="input-group-text" @click="onPasswordConfirmVisible"
                                                        ><i class="fas fa-eye"
                                                    /></span>
                                                </div>
                                                <div class="invalid-feedback">
                                                    {{ confirmPasswordMessage }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="password__validation__errors">
                                            <p v-for="(error, index) in validationErrors.passwordConfirm" :key="index">
                                                {{ error.value }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12 mt-lg-1">
                                        <b-field :message="cgvMessage" type="is-danger">
                                            <b-switch
                                                v-model="cgv"
                                                type="is-dark"
                                                passive-type="is-secondary"
                                                name="cgv"
                                                required
                                            >
                                                {{ $t('register.accept') }}
                                                <a href="#" @click.prevent="openCgv">{{ $t('register.cgv') }}</a>
                                            </b-switch>
                                        </b-field>
                                    </div>
                                    <div class="col-md-12 col-lg-12 mt-lg-5 text-center">
                                        <b-field>
                                            <b-switch
                                                v-model="isPartner"
                                                size="is-large"
                                                type="is-dark"
                                                passive-type="is-secondary"
                                                name="isPartner"
                                            >
                                                {{ $t('register.partner') }}
                                            </b-switch>
                                        </b-field>
                                    </div>
                                </div>

                                <fade-transition>
                                    <div v-show="isPartner" key="partnerDiv" class="mt-3">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label for="society">{{ $t('register.society') }}</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-industry"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="society"
                                                            v-model="user.company"
                                                            class="form-control"
                                                            name="company"
                                                            :placeholder="$t('register.society')"
                                                            type="text"
                                                            :required="isPartner"
                                                        />
                                                        <div class="invalid-feedback">
                                                            {{ $t('register.error.society') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label for="address">{{ $t('register.address') }}</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-mail-bulk"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="address"
                                                            v-model="user.address"
                                                            class="form-control"
                                                            :placeholder="$t('register.address')"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-3">
                                                <label for="postalCode">{{ $t('register.postalCode') }}</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-mail-bulk"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="postalCode"
                                                            v-model="user.cp"
                                                            class="form-control"
                                                            :placeholder="$t('register.postalCode')"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-5">
                                                <label for="ville">{{ $t('register.city') }}</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-mail-bulk"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="ville"
                                                            v-model="user.ville"
                                                            class="form-control"
                                                            :placeholder="$t('register.city')"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-4">
                                                <nl-select
                                                    v-model="user.country"
                                                    class-label="label"
                                                    :name="$t('register.country')"
                                                    :label="$t('register.country')"
                                                    :items="countries"
                                                    prepend-dark
                                                >
                                                    <template #prepend>
                                                        <i class="fas fa-mail-bulk" />
                                                    </template>
                                                </nl-select>
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <label for="siret">Siret</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-industry"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="siret"
                                                            v-model="user.siret"
                                                            class="form-control"
                                                            placeholder="Siret"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-6">
                                                <label for="tva">{{ $t('register.tva') }}</label>
                                                <div class="form-group mb-3 pt-lg-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                ><i class="fas fa-industry"
                                                            /></span>
                                                        </div>
                                                        <input
                                                            id="tva"
                                                            v-model="user.tva_number"
                                                            class="form-control"
                                                            :placeholder="$t('register.tva')"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="society.length <= 0" class="alert alert-danger" role="alert">
                                            {{ $t('register.gains') }}
                                            <br />
                                            {{ $t('register.gainsWarning') }}
                                        </div>
                                        <div
                                            class="custom-control custom-control-alternative custom-checkbox mt-1 checkbox-lg mb-3"
                                        >
                                            <input
                                                id="alertSms"
                                                v-model="user.accept_sms"
                                                class="custom-control-input"
                                                type="checkbox"
                                            />
                                            <label class="custom-control-label" for="alertSms">
                                                <span class="dark">{{ $t('register.alertSMS') }}</span>
                                            </label>
                                        </div>
                                        <div class="col-md-12 col-lg-12 mt-lg-1 pl-0">
                                            <b-field :message="cgvPartnerMessage" type="is-danger">
                                                <b-switch
                                                    v-model="cgvPartner"
                                                    type="is-dark"
                                                    passive-type="is-secondary"
                                                    name="cgvPartner"
                                                >
                                                    {{ $t('register.accept') }}
                                                    <a href="#" @click.prevent="openCgv">
                                                        {{ $t('register.cgvpartner') }}
                                                    </a>
                                                </b-switch>
                                            </b-field>
                                        </div>
                                    </div>
                                </fade-transition>

                                <div class="text-center">
                                    <button
                                        class="btn btn-third mt-5 mb-2 mb-xl-4"
                                        :disabled="loading"
                                        @click.prevent="validForm($event)"
                                    >
                                        {{ $t('register.register') }}
                                    </button>
                                    <NlLoader :show="loading" width="loader-md" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6" />
                        <div class="col-6 text-right">
                            <router-link :to="'/login'" class="text-light">
                                <small>{{ $t('oubli.backToLogin') }}</small>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-xl-block">
            <img class="bg-zebre" src="/assets/img/zebre-jetpack.svg" />
        </div>
        <div class="d-none d-xl-block">
            <img v-show="isPartner" class="bg-zebre-droite" src="/assets/img/zebre-lingot.svg" />
        </div>

        <Footer ref="footer" :fixed="false" />

        <Toaster ref="toaster" />
    </div>
</template>

<style lang="scss">
    .vti__dropdown {
        background-color: white;
    }

    .vti__dropdown :hover {
        background-color: white;
    }

    .errorPhone {
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }

    .label {
        font-weight: 600;
    }

    input.error {
        border-color: #dc3545;
    }

    .password__validation__errors {
        margin-bottom: 10px;

        p {
            margin: 0;
            font-size: 15px;
            color: #dc3545;
        }
    }
</style>

<script>
    import Footer from '@/views/Public/Footer.vue'
    import LangSelector from '@/views/Public/LangSelector.vue'
    import Toaster from '@/components/UI/Toaster'
    import PublicMixin from '@/views/Public/PublicMixin'
    import countryList from '@/utils/countries'
    import { mapActions } from 'vuex'

    export default {
        name: 'Register',
        components: {
            Footer,
            LangSelector,
            Toaster
        },
        mixins: [PublicMixin],
        title: 'register.register',
        data: function () {
            return {
                user: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    telephone: '',
                    password: '',
                    password_confirmation: '',
                    company: '',
                    ville: '',
                    address: '',
                    cp: '',
                    country: '',
                    tva_number: '',
                    siret: '',
                    parrain: '',
                    accept_sms: false,
                    locale: this.$i18n.locale
                },

                validationErrors: {
                    password: [],
                    passwordConfirm: []
                },
                passwordType: 'password',
                passwordConfirmType: 'password',

                cgv: false,
                cgvPartner: false,
                isPartner: false,

                message: null,
                cgvMessage: null,
                cgvPartnerMessage: null,
                confirmPasswordMessage: null,
                telephoneMessage: '',
                validated: false,

                test_params: null,

                phone: null,
                options: {
                    placeholder: this.$t('register.phone'),
                    required: true,
                    id: 'phone'
                },

                country: {
                    fr: 'FR',
                    en: 'GB'
                },

                loading: false
            }
        },
        computed: {
            countries() {
                return countryList[this.$store.state.locale]
            }
        },
        created() {},
        methods: {
            ...mapActions('auth', ['login']),
            showMessage: function () {
                var dataDiv = document.getElementById('vue-data')

                if (dataDiv) {
                    this.message = JSON.parse(dataDiv.dataset.message)

                    this.$refs.toaster.fire('error', this.message.details, this.message.error)
                }
            },
            openCgv() {
                this.$refs.footer.openCgv()
            },
            setValid: function (phoneObject) {
                if (phoneObject && phoneObject.valid === true) {
                    this.phone = phoneObject
                } else {
                    this.phone = null
                }
            },
            validForm: function (event) {
                var isValid = true
                var form = document.getElementById('registration')

                if (form.checkValidity() === false) {
                    isValid = false
                }

                if (!this.cgv) {
                    this.cgvMessage = this.$t('register.error.cgv')
                    isValid = false
                } else {
                    this.cgvMessage = null
                }

                if (this.isPartner === true && this.cgvPartner === false) {
                    this.cgvPartnerMessage = this.$t('register.error.cgv')
                    isValid = false
                } else {
                    this.cgvPartnerMessage = null
                }

                if (this.phone && this.phone.valid && this.phone.valid === true) {
                    this.telephoneMessage = ''
                } else {
                    isValid = false
                }

                if (
                    this.user.password.length > 0 &&
                    this.user.password_confirmation.length > 0 &&
                    this.password != this.password_confirmation
                ) {
                    this.confirmPasswordMessage = this.$t('register.error.passwordMismatch')
                    isValid = false
                } else if (this.user.password_confirmation.length <= 0) {
                    this.confirmPasswordMessage = this.$t('register.error.mandatory')
                    isValid = false
                } else {
                    this.confirmPasswordMessage = ''
                }

                if (this.validationErrors.password.length > 0 || this.validationErrors.passwordConfirm.length > 0) {
                    isValid = false
                }

                if (!isValid) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                this.validated = true
                form.classList.add('was-validated')

                if (isValid) {
                    this.register()
                }
            },
            register: function () {
                this.loading = true

                var user = Object.assign({}, this.user)
                user.telephone = this.phone.number
                user.isPartner = this.isPartner
                user.user = this.user.email

                this.axios
                    .post('/user', user)
                    .then(() => {
                        // TODO: Putting back the google tag for conversion?
                        // this.$gtagevent('conversion', { send_to: 'AW-374683494/l8jTCIq-vo0CEObu1LIB' })
                        this.doLogin()
                    })
                    .catch(error => {
                        console.log(error)

                        if (error.response.status === 422) {
                            this.$store.dispatch('toastError', error.response.data)
                        } else {
                            this.$store.dispatch('toastError', error)
                        }

                        this.loading = false
                    })
            },
            doLogin() {
                this.loginin = true
                this.login({ email: this.user.email, password: this.user.password })
                    .then(() => {
                        this.$router.push(this.$store.state.homepage)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },
            onLangChange(lang) {
                this.$i18n.locale = lang
            },
            onBlurPasswordValidation() {
                this.validationErrors.password = []

                if (this.user.password.length == 0) {
                    this.validationErrors.password.push({
                        key: 'empty',
                        value: this.$t('register.error.mandatory')
                    })
                    return
                }
                if (this.user.password.length < 6)
                    this.validationErrors.password.push({
                        key: 'lenght',
                        value: this.$t('register.error.lenght')
                    })
                if (!/[0-9]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'number',
                        value: this.$t('register.error.number')
                    })
                if (!/[A-Z]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'uppercase',
                        value: this.$t('register.error.uppercase')
                    })
                if (!/[a-z]+/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'lowercase',
                        value: this.$t('register.error.lowercase')
                    })
                if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.user.password))
                    this.validationErrors.password.push({
                        key: 'symbol',
                        value: this.$t('register.error.symbol')
                    })
            },
            onBlurPasswordConfirmValidation() {
                this.validationErrors.passwordConfirm = []

                if (this.user.password_confirmation.length == 0) {
                    this.validationErrors.passwordConfirm.push({
                        key: 'empty',
                        value: this.$t('register.error.mandatory')
                    })
                    return
                }
                if (this.user.password_confirmation != this.user.password)
                    this.validationErrors.passwordConfirm.push({
                        key: 'confirmation',
                        value: this.$t('register.error.mismatch')
                    })
            },
            onPasswordVisible() {
                this.passwordType = !this.passwordType
            },
            onPasswordConfirmVisible() {
                this.passwordConfirmType = !this.passwordConfirmType
            }
        },
        mounted() {
            this.showMessage()
            this.user.parrain = new URLSearchParams(window.location.search).get('ref')
        }
    }
</script>